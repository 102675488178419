import axios from "axios";
import beasconfig from "./beasconfig.js";
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  baseURL: beasconfig.apiAudioUrl, //录音地址
  // baseURL: 'https://audio.xin-ptt.com',        //正式
  timeout: 60 * 60 * 24 * 1000
});

export default service;
