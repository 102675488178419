<template>
    <el-dialog :title="tipsData.title" :append-to-body="true" :visible.sync="selfgroup" :before-close="handleClose" center>
        <div class="dialog">
            <div class="dialog_box">
                <div class="dialog_box_input">
                    {{ tipsData.content }}
                </div>
            </div>
        </div>
        <div class="bottom">
            <el-button class="bc" @click="submitForm()">确定</el-button>
            <el-button class="gb" @click="handleClose()">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>

export default {
    props: {
        tipsData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            selfgroup: true,
            isPreventClick: false
        };
    },
    created() {

    },
    beforeCreate: function () { },
    methods: {
        submitForm() {       //开启
            this.$emit('successTips');
        },
        handleClose() {     //关闭
            this.$emit('closeTips');
        }
    },
    mounted() {

    },
    watch: {

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.el-dialog__wrapper {
    display: flex;
    align-items: center;
}

/deep/.el-dialog {
    width: 405px;
    background: #4D4D4D;
    margin-top: -5vh !important;

    &__header {
        padding: 20px 30px;
    }

    &__title {
        color: #fff;
        font-size: 16px;
    }

    &__headerbtn {
        right: 30px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: var(--main-color) !important;
    }

    &--center .el-dialog__body {
        padding: 0px 30px 0px;
    }

}

.dialog {
    width: 100%;
    color: #fff;
    background-color: #383838;
    border: 1px solid #575757;
    margin: auto;

    &_box {
        padding: 20px 25px;
        display: flex;
        align-items: center;

        &_input {
            width: 100%;
            font-size: 16px;
            text-align: center;
        }

    }
}

.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;

    /deep/.gb.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #fff !important;
        background-color: transparent !important;
        color: #fff !important;
    }

    /deep/.bc.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid var(--main-color) !important;
        background-color: transparent !important;
        color: var(--main-color) !important;
        margin-right: 30px;
    }
}
</style>