const replaceParamVal = (url, paramName, replaceWith) => {
  var oUrl = url;
  var re = eval('/(' + paramName + '=)([^&]*)/gi');
  var nUrl = oUrl.replace(re, paramName + '=' + replaceWith);
  return nUrl
}


const isEmpty = (obj) => {    //检测是否为空对象
  return Reflect.ownKeys(obj).length === 0 && obj.constructor === Object;
}

const compare = (a, b) => {      //比较两个时间大小
  return a.getTime() > b.getTime();
}

const getRandomColor = () => {       //获取随机颜色
  return `#${Math.floor(Math.random() * 0xffffff).toString(16)}`;
}

const stringDirection = (angle) => {  //根据角度计算方向
  var num = parseInt(angle)
  var N = '北';
  var E = '东';
  var S = '南';
  var W = '西';
  var dir = '';

  if (num == 0 || num == 360) {  //北
    dir = '正' + N;
  } else if (num < 90 && num > 0) {
    if (num < 45) {
      dir = N + '偏' + E + num + ' °';
    } else if (num == 45) {
      dir = E + N + num + ' °';
    } else if (num > 45) {
      dir = E + '偏' + N + (90 - num) + ' °';
    }
  } else if (num == 90) { //东
    dir = '正' + E;
  } else if (num < 180 && num > 90) {
    if (num < 135) {
      dir = E + '偏' + S + (num - 90) + ' °';
    } else if (num == 135) {
      dir = E + S + (num - 90) + ' °';
    } else if (num > 135) {
      dir = S + '偏' + E + (180 - num) + ' °';
    }
  } else if (num == 180) {   //南
    dir = '正' + S;
  } else if (num < 270 && num > 180) {

    if (num < 225) {
      dir = S + '偏' + W + (num - 180) + ' °';
    } else if (num == 225) {
      dir = W + S + (num - 180) + ' °';
    } else if (num > 225) {
      dir = W + '偏' + S + (270 - num) + ' °';
    }
  } else if (num == 270) { //西
    dir = '正' + W;
  } else if (num < 360 && num > 270) {
    if (num < 315) {
      dir = W + '偏' + N + (num - 270) + ' °';
    } else if (num == 315) {
      dir = W + N + (num - 270) + ' °';
    } else if (num > 315) {
      dir = S + '偏' + W + (360 - num) + ' °';
    }
  }

  return dir;
}
export default { replaceParamVal, isEmpty, compare, getRandomColor, stringDirection }