<template>
    <el-dialog title="新建群组" :append-to-body="true" :visible.sync="selfgroup" :before-close="handleClose" center>
        <div class="dialog">
            <div class="dialog_box">
                <div class="dialog_box_input">
                    <el-input v-model.trim="groupName" maxlength="15" @input="bindInput" placeholder="请输入群组名称">
                    </el-input>
                </div>
                <div style="color:#FF0000;min-width:40%;">
                    由2-15位数字,中文,英文组成
                </div>
            </div>
        </div>
        <div class="bottom">
            <el-button class="bc" @click="submitForm()">确定</el-button>
            <el-button class="gb" @click="handleClose()">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import regfunc from "@/method/form/regfunc.js";
import axios from "axios";
import qs from "qs";
import beasconfig from '@/api/beasconfig.js';
const selfUrlapi = beasconfig.selfUrlapi;
export default {
    props: {},
    data() {
        return {
            groupName: '',
            selfgroup: true,
            isPreventClick: false
        };
    },
    created() {

    },
    beforeCreate: function () { },
    methods: {
        submitForm() {       //开启
            if (this.groupName == "") {

                this.$MessageWarning('请输入群组名称');
                return
            }
            let name = this.groupName ? this.groupName.replace(regfunc.trim, "") : '';
            if (!regfunc.rGroupName.test(name)) {
                this.$MessageWarning('群组名称由2-15位数字,中文,英文组成');
                return
            }
            if (this.isPreventClick) {

                return
            }
            this.isPreventClick = true;
            axios.post(`${selfUrlapi}/group/addGroup`, qs.stringify({
                creator: this.$store.state.userInfo.User_ID,
                groupName: this.groupName
            }),
                {
                    headers: beasconfig.tokenSl
                })
                .then(res => {
                    this.isPreventClick = false;
                    if (res.data.code == 0) {
                        this.$MessageSuccess('群组创建成功');
                        this.$emit('selfgroupSuccess');
                    } else if (res.data.code == 1001) {
                        this.$MessageWarning('用户自建组功能未开启');
                    } else if (res.data.code == 1003) {
                        this.$MessageWarning('群组名称已存在，请重新输入');
                    } else {
                        this.$MessageWarning(res.data.msg);
                    }
                })
        },
        handleClose() {     //关闭
            this.$emit('selfgroupClose');
        },
        bindInput(e) {        //不能输入
            let m = e.length;
            if (m > 15) {
                this.groupName = this.inputLimit.name;
                return
            }
            this.groupName = e.replace(regfunc.trimTow, ' ');
            this.groupName = this.groupName.substring(0, 15);
            // this.groupName = regfunc.noCharacter(e);
        }
    },
    mounted() {

    },
    watch: {

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.el-dialog__wrapper {
    display: flex;
    align-items: center;
}

/deep/.el-dialog {
    min-width: 600px !important;
    max-width: 800px !important;
    background: #4D4D4D;
    margin-top: -5vh !important;

    &__header {
        padding: 20px 30px;
    }

    &__title {
        color: #fff;
        font-size: 16px;
    }

    &__headerbtn {
        right: 30px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: var(--main-color) !important;
    }

    &--center .el-dialog__body {
        padding: 0px 30px 0px;
    }

    .el-input__inner {
        width: 100%;
        height: 45px;
        background-color: #4D4D4D;
        border: 1px solid #4D4D4D;
        color: #fff;
        border-radius: 2px;
    }

}

.dialog {
    width: 100%;
    color: #fff;
    background-color: #383838;
    border: 1px solid #575757;
    margin: auto;

    &_box {
        padding: 20px 25px;
        display: flex;
        align-items: center;

        &_input {
            flex: 1;
            margin-right: 10px;
        }

    }
}

.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;

    /deep/.gb.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #fff !important;
        background-color: transparent !important;
        color: #fff !important;
    }

    /deep/.bc.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid var(--main-color) !important;
        background-color: transparent !important;
        color: var(--main-color) !important;
        margin-right: 30px;
    }
}
</style>