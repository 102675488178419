<template>
    <el-dialog :title="joinType == 0 ? '加入群组' : ' '" :append-to-body="true" :visible.sync="isJoingroup"
        :before-close="handleClose" center>
        <div class="dialog" :style="joinType == 1 ? 'margin:15px 0px 20px 0px;' : ''">
            <div class="dialog_box">
                <div class="dialog_box_input boxcolor disflexrow" style="padding:10px 0;">
                    <div class="border" v-for="(item, index) in inputList" :key="index">
                        <input maxlength="1" type="text" v-model="item.val" class="border-input"
                            @input="bindInput($event, index)" @keyup="nextFocus($event, index)"
                            @keydown="changeValue(index)">
                        <!-- :disabled="item.isd" -->
                        <div v-if="item.isd" class="border-box"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <template v-if="joinType == 0">
                <el-button class="bc" @click="submitForm()">确定</el-button>
                <el-button class="gb" @click="handleClose()">取消</el-button>
            </template>
        </div>
    </el-dialog>
</template>

<script>
import regfunc from "@/method/form/regfunc.js";
import axios from "axios";
import qs from "qs";
import beasconfig from '@/api/beasconfig.js';
const selfUrlapi = beasconfig.selfUrlapi;
export default {
    props: {
        joinType: {
            type: Number,
            default: 0
        },
        seflGroupData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            isJoingroup: true,
            inputList: [{
                val: "",
                isd: false
            }, {
                val: "",
                isd: true
            }, {
                val: "",
                isd: true
            }, {
                val: "",
                isd: true
            }, {
                val: "",
                isd: true
            }, {
                val: "",
                isd: true
            }],
            isPreventClick: false
        };
    },
    created() {
        if (this.joinType == 1) {
            this.getCode();
        }
    },
    beforeCreate: function () { },
    methods: {
        bindInput(e, index) {
            let val = this.restrictInputToNumbers(this.inputList[index].val);
            this.inputList[index].val = val;
            let last = index + 1;
            if (val && index < 5) {
                this.inputList[index].isd = true;
                this.inputList[last].isd = false;
            }
        },
        restrictInputToNumbers(input) {
            return input ? input.replace(/[^0-9]/g, '') : '';
        },
        /*对焦到下一个input框去*/
        nextFocus(el, index) {
            var nextIndex = index + 1;
            var lastIndex = index - 1;
            var dom = document.getElementsByClassName("border-input"),
                // currInput = dom[index],
                nextInput = dom[nextIndex],
                lastInput = dom[lastIndex];
            let val = this.restrictInputToNumbers(this.inputList[index].val);
            /*这里的keyCode 根据不同的平台或许不同,安卓就是不是8*/
            if (el.keyCode != 8) {
                if (index < (this.inputList.length - 1)) {
                    if (val) {
                        nextInput.focus();
                    }
                }
                // else {
                //     currInput.blur();
                // }
            } else {
                if (index != 0) {
                    lastInput.focus();
                    this.inputList[index].isd = true;
                    this.inputList[lastIndex].isd = false;
                }
            }
        },
        /*当键盘按下的时候清空原有的数*/
        changeValue(index) {
            this.inputList[index].val = "";
        },
        submitForm() {       //开启
            let code = this.inputList.map(item => item.val).join('');
            if (code == "") {
                this.$MessageWarning('请输入群口令');
                return
            }
            if (code.length < 6 || !regfunc.rNum.test(code)) {
                this.$MessageWarning('您输入的群口令错误');
                return
            }
            if (this.isPreventClick) {

                return
            }
            this.isPreventClick = true;
            axios.post(`${selfUrlapi}/code/joinGroup`, qs.stringify({
                uId: this.$store.state.userInfo.User_ID,
                code: code
            }),
                {
                    headers: beasconfig.tokenSl
                })
                .then(res => {
                    this.isPreventClick = false;
                    if (res.data.code == 0) {
                        this.$emit('joingroupSuccess');
                    } else if (res.data.code == 1004) {
                        this.$MessageWarning('您输入的群口令错误');
                    } else {
                        this.$MessageWarning(res.data.msg);
                    }
                })
        },
        handleClose() {     //关闭
            this.$emit('joingroupClose');
        },
        getCode() {
            axios.post(`${selfUrlapi}/code/getCode`, qs.stringify({
                uId: this.$store.state.userInfo.User_ID,
                cgId: this.seflGroupData.id
            }),
                {
                    headers: beasconfig.tokenSl
                })
                .then(res => {
                    let data = res.data.data;
                    if (data) {
                        this.inputList.forEach((item, index) => {
                            item.isd = true;
                            item.val = data[index];
                        })
                    }
                })
        }
    },
    mounted() {

    },
    watch: {

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.el-dialog__wrapper {
    display: flex;
    align-items: center;
}

/deep/.el-dialog {
    width: 405px;
    background: #4D4D4D;
    margin-top: -5vh !important;

    &__header {
        padding: 20px 30px;
    }

    &__title {
        color: #fff;
        font-size: 16px;
    }

    &__headerbtn {
        right: 30px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: var(--main-color) !important;
    }

    &--center .el-dialog__body {
        padding: 0px 30px 0px;
    }

}

.dialog {
    width: 100%;
    color: #fff;
    background-color: #383838;
    border: 1px solid #575757;
    margin: auto;

    &_box {
        padding: 10px 0px;
        display: flex;
        align-items: center;

        &_input {
            flex: 1;
        }

    }
}

.bottom {
    width: 100%;
    text-align: center;
    padding: 15px 0px;

    /deep/.gb.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid #fff !important;
        background-color: transparent !important;
        color: #fff !important;
    }

    /deep/.bc.el-button {
        width: 89px;
        height: 38px;
        border: 1px solid var(--main-color) !important;
        background-color: transparent !important;
        color: var(--main-color) !important;
        margin-right: 30px;
    }
}

.btndsicl {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.disflexrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.border {
    width: 40px;
    height: 40px;
    position: relative;
    margin-right: 10px;
}

.border-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}

.border-input {
    color: #E4E4E4;
    background: #4D4D4D;
    width: 40px;
    font-size: 20px;
    height: 40px;
    text-align: center;
    border: 1px solid #333333;
    color: inherit;
    font-family: inherit;
    border: 0;
    outline: 0;
}

.border-input:nth-child(6) {
    margin-right: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}
</style>